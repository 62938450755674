.home-container {
  background-image: url('background-image.png');
  /* replace with your image url */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  flex-direction: column;
  color: white;
    /* add this line */
}