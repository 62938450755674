.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.header {
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 25px;
  font-family: sans-serif;
}

.signOutButton {
  background-color: lightgrey;
  /* replace #f00 with the color you want */
  color: black;
  /* This is for the text color, replace #fff with the color you want */
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196F3;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.product-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.product-container>div {
  margin: 10px;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-label {
  margin: 0 10px;
}


.subscribeButton {
  background-color: #2196F3;
  color: #fff;
}

.product-card{
  border: 1px solid lightgray;
  padding: 15px;
  border-radius: 10px;
  background-color: white;
}

.description-row {
  /*display: flex;*/
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  /* or however much vertical spacing you want between rows */
}

.description-title {
  font-weight: bold;
  margin-right: 10px;
  font-size: 20px;
  /* or however much horizontal spacing you want between title and text */
}

.description-text {
  flex-grow: 1;
  font-size: 12px;
  word-wrap: break-word;
  max-width: 275px;
}