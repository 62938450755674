.contactUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.contactUs h1 {
  margin-bottom: 20px;
}