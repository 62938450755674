/* src/Login.css */
body,
html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

#root {
  height: 100%;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}

button {
  width: 250px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

button img {
  width: 25px;
  height: 30px;
  margin-right: 10px;
}

button:hover {
  opacity: 0.8;
}

button:nth-child(2) {
  background: #4285f4;
}

button:nth-child(3) {
  background: #000;
}